<template>
  <section class="person part" :class="[ payload.optionen ]">
    <div class="constrain">
      <div class="person-row">
        <div class="content left">
          <div v-view class="image animate reveal">
            <Image v-view :src="payload.bild" />
          </div>
          <span v-view class="quote hidden md:block animate in-bottom" v-html="payload.quote" />
        </div>
        <div v-view class="content right animate in-bottom">
          <h2 class="name" v-html="payload.name" />
          <h3 class="job" v-html="payload.beruf" />
          <section class="description" v-html="payload.beschreibung" />
          <section class="quote md:hidden block" v-html="payload.quote" />
          <Go class="phone" :to="payload.telefon" v-text="payload.telefon" />
          <Go class="mail" :to="payload.email" v-text="payload.email" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";
@import "@/assets/styles/_grid.scss";
@import "@/assets/styles/_type.scss";

@screen md {
  .person + .person {
    margin-top: px(161);

    :deep(.image) {
      margin-top: px(-392);
    }
  }
}

h2 {
  position: relative;

  @include responsive('margin-bottom', px(6), px(20));
  @include responsive('margin-left', px(27), 0);

  // Line
  &::before {
    @apply bg-black;

    content: "";
    position: absolute;

    .person.right & {
      right: initial;
      @include responsive('left', px(-13.5), 120%);
    }

    @include responsive('transform', initial, translateY(-50%));
    @include responsive('top', initial, 50%);
    @include responsive('right', initial, calc(100% + #{px(39)}));
    @include responsive('left', px(-13.5), initial);
    @include responsive('bottom', px(-28), initial);
    @include responsive('width', px(2), px(154));
    @include responsive('height', px(90), px(1.5));
  }
}

h3 {
  @include responsive('margin-bottom', px(32), px(59));
  @include responsive('margin-left', px(27), 0);
}

.description {
  @include responsive('margin-bottom', px(28), px(65));
}

.phone, .mail {
  display: block;
  @include responsive('margin-bottom', px(8), px(15));
}

.phone {
  @include responsive('margin-top', px(31), 0);
}

.mail {
  text-decoration: underline;
}

.person-row {
  display: grid;
  grid-column-gap: px(134);
  @include responsive('grid-template-columns', 1fr, 1fr 1fr);
}

.content.right {
  @include responsive('margin-right', 0, 12.5%);
  @include responsive('padding-top', px(18), px(74));
}

.right .person-row {
  .content {
    @include responsive('grid-row', unset, 1);
  }
  .content.left {
    @include responsive('grid-column', 1, 2);
  }
  .content.right {
    @include responsive('margin-left', 0, 12.5%);
    @include responsive('grid-column', 1, 1);
  }

  .quote {
    text-align: right;
  }
}

.image {
  @apply rounded-big;
  overflow: hidden;

  img {
    height: 100%;
    object-fit: cover;
  }
}

.content.left {
  display: grid;
  :deep(.image) {
    @apply rounded-big;
  }
}

.quote {
  @apply text-primary;
  @extend .h3;

  @include responsive('margin-top', px(30), px(40));
  @include responsive('margin-left', px(23), 0);
}
</style>
